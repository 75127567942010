import React from "react"
import { useTranslation } from "react-i18next";
import cn from 'classnames';
import styles from './LanguageSwitcher.module.css';

import { languages } from '../../App';


function LanguageSwitcher({currentLang = "de"}) {
  
  const [t, i18n] = useTranslation('common');
  
  const onLanguageClick = e => {
    const langCode = e.target.innerText.toLowerCase();
    i18n.changeLanguage(langCode);
  } 
  
  return (
    <ul className={styles.languagesList}>
      { languages.map((lang, index) => (
        <React.Fragment key={index} >
          <li 
            onClick={onLanguageClick} 
            className={cn(
              styles.languageElement,
              currentLang === lang ? styles.currentLanguage : ""
            )}>
            {lang}
          </li>
          { ((index + 1) < languages.length) && (<span>&nbsp;|&nbsp;</span>) } 
        </React.Fragment>
      ))}
    </ul>
  );
}

export default LanguageSwitcher;