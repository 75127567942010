import React from "react";
import cn from "classnames";
import styles from './TopBar.module.scss';
import { useTranslation } from "react-i18next";

import HeartFull  from "../../images/heart-full.svg";
import HeartEmpty from "../../images/heart-empty.svg";

function TopBar({ 
  livesCount, 
  score, 
  livesCountDefault, 
  isHidden 
}) {

  const [t, i18n] = useTranslation('common');

  return (
    <div 
      className={cn(
        styles.wrapper,
        isHidden ? styles.topBarHidden : ""
    )}>
      <div className={styles.livesBlock}>
        {Array(livesCountDefault).fill(1).map((el, i) =>
          <React.Fragment key={i}>
            { (livesCount >= i + 1) ? (
              <img src={HeartFull} alt="full" />
            ) : (
              <img src={HeartEmpty} alt="empty" />
            )}
          </React.Fragment>
        )}
      </div>
      <div className={styles.scoreBlock}>
        <p>
          <strong>{t("Punkte")}: <span>{ score && score }</span></strong>
        </p>
      </div>
    </div>
  )
}

export default React.memo(TopBar);