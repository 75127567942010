import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from './GameResultScreen.module.scss';
import Shields from "../components/Shields";
import InfoBlock from "../components/InfoBlock";
import Squirrel from "../components/Squirrel";

import { EnterYourDataScreenUrl } from '../App';
import {fetchTranslations} from "../api";
import {useCustomT} from "../hooks/useCustomT";
import {BasicRichText} from "../components/BasicRichText";

function GameResultScreen({ sounds }) {
  const { t, i18n } = useTranslation('common');
  const customT = useCustomT();
  const navigate = useNavigate();
  const score = sessionStorage.getItem("score");

  const [firstSecondsPassed, setFirstSecondsPassed] = useState(false);
  const [registerTest, setRegisterText] = useState(null)

  const navigateToFirstScreen = useCallback(() => {
    sounds.changeScreenSoundPlay();
    navigate('/');
  }, [navigate, sounds])

  const navigateToNextScreen = useCallback(() => {
    sounds.changeScreenSoundPlay();
    navigate(EnterYourDataScreenUrl);
  }, [navigate, sounds])

  const onKeyDown = useCallback(e => {
    if (e.keyCode == "37") {
      navigateToFirstScreen();
    } else if (e.keyCode == "39") {
      navigateToNextScreen();
    }
  }, []);

  useEffect(() => {
    const timerTime = 2000;
    const firstSecondsTimeoutId = setTimeout(() => {
      setFirstSecondsPassed(true);
    }, timerTime)
    setTimeout(() => {
      window.addEventListener("keydown", onKeyDown);
    }, timerTime)
    return () => { 
      window.removeEventListener("keydown", onKeyDown);
      clearTimeout(firstSecondsTimeoutId);
    }
  }, []);

  useEffect(() => {
    const fetchAndSetTermsText = async () => {
      const resp = await fetchTranslations();
      if (resp.translations && resp.translations.length) {
        const text = resp.translations[0].registration_text;
        if (text) {
          setRegisterText(text);
        }
      }
    }
    fetchAndSetTermsText();
  }, [])
  
  return (
    <div className={styles.wrapper}>
      <InfoBlock customStyles={styles.infoBlock}>
        <p 
          className={styles.simpleText}
          dangerouslySetInnerHTML={{ __html: t("congratulations-for-result", { score: score && score })}}
        />
        <div className={styles.squirrelWrapper}>
          <Squirrel status="catch" customStyles={styles.squirrel}/>
        </div>
        <p className={styles.simpleText}>
          { registerTest ?
              <BasicRichText blocks={customT(registerTest)} />
              : t("register-text")
          }
        </p>
      </InfoBlock>
      <Shields 
        leftShield={{
          text: t("Erneut spielen"),
          onClickHandler: firstSecondsPassed ? navigateToFirstScreen : null,
        }}
        rightShield={{
          text: t("Jetzt registrieren!"),
          onClickHandler: navigateToNextScreen,
        }}
        customWrapperStyles={cn(
          styles.shields,
          !firstSecondsPassed ? styles.inVisible : null
        )}
      />
    </div>
    
  );
}

export default GameResultScreen;