import React from "react";
import cn from "classnames";
import styles from './Shield.module.scss';

import ShieldLeft from "../images/shield-left.svg";
import ShieldRight from "../images/shield-right.svg";

function Shield({
  type = "left", 
  text = "", 
  onClick, 
  customWrapperStyles
}) {
  let Shield = ShieldLeft;
  if (type === "right") {
    Shield = ShieldRight;
  }
  return (
    <div 
      onClick={onClick} 
      className={cn(
        styles.wrapper, 
        customWrapperStyles,
        type === "right" ? styles.wrapperRight : styles.wrapperLeft
      )}
    >
      <img 
        src={Shield} 
        className={styles.image}
        alt={type === "right" ? "move forward" : "go back"}
      />
      <div className={styles.text}> 
        <p>{text}</p>
      </div>
    </div> 
  );
}

export default Shield;