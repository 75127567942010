import React from "react";
import cn from 'classnames';
import styles from './Squirrel.module.css';

import SquirrelStraight from '../images/squirrel.svg';
import SquirrelLeft from '../images/squirrel-left.svg';
import SquirrelRight from '../images/squirrel-right.svg';
import SquirrelHit from '../images/squirrel-hit.svg';
import SquirrelCatch from '../images/squirrel-catch.svg';

function Squirrel({ 
  customRef, 
  status, 
  customStyles, 
  leftPosition, 
  widthPercents,
  isBlinking
}) {
  let SquirrelImage;
  switch(status) {
    case "left": SquirrelImage = SquirrelLeft
    break
    case "right": SquirrelImage = SquirrelRight
    break
    case "catch": SquirrelImage = SquirrelCatch
    break
    case "hit": SquirrelImage = SquirrelHit
    break
    default: SquirrelImage = SquirrelStraight
  }
  return (
    <img 
      src={SquirrelImage}
      ref={customRef}
      style={{"left": `${leftPosition}%`, "width": `${widthPercents}%`}}
      className={cn(
        styles.squirrelWidth, 
        customStyles,
        isBlinking ? styles.blinking : ""
      )}
    />
  );
}

export default React.memo(Squirrel);