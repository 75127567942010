import React, { useCallback, useEffect, useState } from "react"
import cn from "classnames";
import styles from './GameScreen.module.scss';

import { useNavigate } from 'react-router-dom';
import Squirrel from "../../components/Squirrel";
import TopBar from "./TopBar";
import Row from "./Row";
import { fetchFallingObjects, fetchGameSettings } from "../../api";

import {
  calculateGridStepPercents,
  animationTimeSecToIntervalTickSpeed,
  checkGameSettingsAreValid,
  generateRow,
  generateRows,
  calculatePosition,
  calculateAnimationIntervalTime,
  isColliding,
  getTranslateYValue,
} from "./helpers";

import { GameResultScreenUrl } from "../../App";

import ArrowLeft from "../../images/arrow-left.svg";
import ArrowRight from "../../images/arrow-right.svg";


const timerCount = 3;
const squirrelUntouchableTimeSec = 5;
const livesCountDefault = 3;
export const animationSpeedCoeff = 4;

function GameScreen({ sounds }) {
  const navigate = useNavigate();
  
  // State
  const [gameSettings, setGameSettings] = useState(null);
  const [timerPanelText, setTimerPanelText] = useState(timerCount);
  const [gameIsStarted, setGameIsStarted] = useState(false);
  const [livesCount, setLivesCount] = useState(livesCountDefault);
  const [score, setScore] = useState(0);
  const [squirrelPosition, setSquirrelPosition] = useState(50);
  const [squirrelStatus, setSquirrelStatus] = useState("straight");
  const [fallingObjectsRows, setFallingObjectsRows] = useState([]); 
  const [fallingObjects, setFallingObjects] = useState([]);
  const [animateIntervalId, setAnimateIntervalId] = useState(null);
  const [animationIntervalTime, setAnimationIntervalTime] = useState(50);
  const [
    animationSpeedIncreasingIntervalId, 
    setAnimationSpeedIncreasingIntervalId
  ] = useState(null);
  const [squirrelIsUntouchable, setSquirrelIsUntouchable] = useState(false);
  const [squirrelStatusTimeoutId, setSquirrelStatusTimeoutId] = useState(null);
  const [isErrorOccurred, setIsErrorOccurred] = useState(false);

  
  // Refs
  const columnsCountRef = React.useRef(null);
  const squirrelPositionRef = React.useRef(null);
  const squirrelRef = React.useRef(null);
  const animateIntervalIdRef = React.useRef(null);
  const fallingObjectsInnerRef = React.useRef(null);
  const fallingObjectsRef = React.useRef(null);
  let rowsRef = React.useRef({});
  let fallingObjectRefs = React.useRef({});
  const squirrelIsUntouchableRef = React.useRef(null);
  const rowsIntersectionObserverRef = React.useRef(null);
  const squirrelStatusTimeoutIdRef = React.useRef(null);

  const startTimer = useCallback(() => {
    let count = 0;
    const intervalId = setInterval(() => {
      if (count <= timerCount && !((timerCount - count) === timerCount)) {
        sounds.timerTickSoundPlay();
        setTimerPanelText(timerCount - count);
      }
      if (count === timerCount) {
        setTimerPanelText("Go");
      }
      if (count > timerCount) {
        clearInterval(intervalId);
        setGameIsStarted(true);
        return;
      }
      count++;
    }, 1000)
  }, [sounds])

  const updateSquirrelPosition = direction => {
    const positionInfo = calculatePosition(
      squirrelPositionRef.current, 
      direction, 
      calculateGridStepPercents(columnsCountRef.current)
    );
    setSquirrelStatus(positionInfo.direction);
    // clearTimeout of changing squirrel status (after catch or hit)
    // as it has been already changed  
    clearTimeout(squirrelStatusTimeoutIdRef.current);
    setSquirrelPosition(positionInfo.position);
  }
  
  const onArrowsClick = useCallback((e) => {
    const arrowDirection = e.target.id;
    updateSquirrelPosition(arrowDirection);
  }, [setSquirrelPosition])

  const onKeyDown = useCallback(e => {
    // On the left arrow click
    if (e.keyCode == "37") {
      updateSquirrelPosition("left");
    // On the right arrow click
    } else if (e.keyCode == "39") {
      updateSquirrelPosition("right");
    } 
  }, [setSquirrelPosition]);

  const updateRows = (fallingObjects) => {
    const newRow = generateRow(fallingObjects, columnsCountRef.current);
    setFallingObjectsRows(oldRows => {
      let newRows = [...oldRows, newRow];
      newRows.shift();
      return newRows;
    });
  }

  const getCurrentRowRef = () => {
    return rowsRef.current[fallingObjectsRows[0].id];
  }

  const onIntersection = () => {
    changeOffsetForOneRowHeight();
    rowsIntersectionObserverRef.current.disconnect(getCurrentRowRef());
    updateRows(fallingObjects);
  }

  const checkForCollision = () => {
    const keys = Object.keys(fallingObjectRefs.current);
    if (squirrelRef.current) {
      keys.forEach(key => {
        if (fallingObjectRefs.current[key]) {
          const currentObject = fallingObjectRefs.current[key];
          if (isColliding(currentObject, squirrelRef.current)) {
            // clearTimeout of changing squirrel status (after catch or hit)
            // as it will be changed anyway on the collision event
            // and new timeout will be set
            clearTimeout(squirrelStatusTimeoutIdRef.current);
            // check with what falling object squirrel has current collision
            // and do appropriate logic for dangerous and not dangerous objects
            fallingObjects.forEach(obj => {
              if (obj.title === currentObject.title) {
                if (obj.danger_object) {
                  if (!squirrelIsUntouchableRef.current) {
                    sounds.hitSoundPlay();
                    setLivesCount(oldCount => oldCount - 1);
                    setSquirrelIsUntouchable(true);
                    setTimeout(() => {
                      setSquirrelIsUntouchable(false);
                    }, squirrelUntouchableTimeSec * 1000);
                    setSquirrelStatus("hit");
                    const statusTimeoutId = setTimeout(() => {
                      setSquirrelStatus("straight");
                    }, 1500)
                    setSquirrelStatusTimeoutId(statusTimeoutId);
                  }
                } else {
                  sounds.catchSoundPlay();
                  setScore(oldScore => oldScore + obj.points);
                  setSquirrelStatus("catch");
                  const statusTimeoutId = setTimeout(() => {
                    setSquirrelStatus("straight");
                  }, 400)
                  setSquirrelStatusTimeoutId(statusTimeoutId);
                }
              }
            })
            currentObject.style.display = "none";
          }
        }
      })
    }
  }

  const animationSpeedIncreasing = () => {
    let counter = 1;
    let intervalTime = animationIntervalTime;
    const interval = setInterval(() => {
      intervalTime = calculateAnimationIntervalTime(
        intervalTime, 
        counter,
        gameSettings.acceleration,
        gameSettings.start_speed,
      );
      counter++;
      setAnimationIntervalTime(intervalTime);
    }, gameSettings.interval * 1000);
    setAnimationSpeedIncreasingIntervalId(interval);
  }

  const changeOffsetForOneRowHeight = () => {
    const currentTranslateY = getTranslateYValue(fallingObjectsRef.current.style.transform);
    const newValue = currentTranslateY - 20;
    fallingObjectsRef.current.style.transform = `translateY(${newValue}vh)`;
  }

  const setInitialOffset = () => {
    const initialOffset = -140;
    fallingObjectsRef.current.style.transform = `translateY(${initialOffset}vh)`;
  }

  const animate = () => {
    if (
      fallingObjectsRef && 
      fallingObjectsRef.current && 
      fallingObjectsRef.current.style
    ) {
      const intervalId = setInterval(() => {
        const currentTranslateY = getTranslateYValue(fallingObjectsRef.current.style.transform);
        const newValue = currentTranslateY + (animationSpeedCoeff / 10);
        fallingObjectsRef.current.style.transform = `translateY(${newValue}vh)`;
        checkForCollision();
      }, animationIntervalTime);
      setAnimateIntervalId(intervalId);
    }
  }

  const setRowIntersectionObserver = () => {
    const observerOptions = {
      root: fallingObjectsInnerRef.current,
      threshold: 0.5,
      rootMargin: '5px'
    };
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          onIntersection();
        }
      });
    }, observerOptions);
    let currentRow = getCurrentRowRef();
    if (currentRow) {
      observer.observe(currentRow);
    }
    rowsIntersectionObserverRef.current = observer;
  }

  // use such approach because Error Boundry can't
  // catch error from asynchronous code  
  const onResponseError = () => {
    setIsErrorOccurred(true);
  }

  // Effects
  useEffect(() => {
    startTimer();
    window.addEventListener("keydown", onKeyDown);
    return () => { 
      window.removeEventListener("keydown", onKeyDown);
      sounds.backgroundMusicStop();
    };
  }, []);

  useEffect(() => {
    // initially generating of first 5 rows
    if (fallingObjects && fallingObjects.length && gameSettings) {
      setFallingObjectsRows(generateRows(5, fallingObjects, gameSettings.columns));
    }
  }, [fallingObjects, gameSettings]);

  useEffect(() => {
    if (animateIntervalId) {
      animateIntervalIdRef.current = animateIntervalId;
    }
    return () => clearInterval(animateIntervalId);
  }, [animateIntervalId])

  useEffect(() => {
    return () => clearInterval(animationSpeedIncreasingIntervalId);
  }, [animationSpeedIncreasingIntervalId])

  useEffect(() => {
    squirrelStatusTimeoutIdRef.current = squirrelStatusTimeoutId;
    return () => clearTimeout(squirrelStatusTimeoutId);
  }, [squirrelStatusTimeoutId])

  useEffect(() => {
    squirrelPositionRef.current = squirrelPosition;
  }, [squirrelPosition]) 

  useEffect(() => {
    squirrelIsUntouchableRef.current = squirrelIsUntouchable;
  }, [squirrelIsUntouchable])

  useEffect(() => {
    const fetchAndSetFallingObjects = async () => {
      const objects = await fetchFallingObjects(onResponseError);
      setFallingObjects(objects);
    }
    const fetchAndSetGameSettings = async () => {
      const settingsArr = await fetchGameSettings(onResponseError);
      if (settingsArr.engine && settingsArr.engine.length) {
        const settingsObj = settingsArr.engine[0];
        if (checkGameSettingsAreValid(settingsObj)) {
          setGameSettings(settingsObj);
          setAnimationIntervalTime(animationTimeSecToIntervalTickSpeed(settingsObj.start_speed));
          columnsCountRef.current = settingsObj.columns;
        } else {
          console.error("Game settings is invalid !")
        }
      }
    }
    fetchAndSetFallingObjects();
    fetchAndSetGameSettings();
  }, []);

  useEffect(() => {
    if (fallingObjectsRows && fallingObjectsRows.length) {
      setRowIntersectionObserver();
    }
  }, [fallingObjectsRows])

  useEffect(() => {
    if (animationIntervalTime && gameIsStarted) {
      if (animateIntervalId) {
        clearInterval(animateIntervalId);
      }
      animate();
    }
  }, [animationIntervalTime, gameIsStarted])

  useEffect(() => {
    if (gameIsStarted && gameSettings) {
      setInitialOffset();
      animationSpeedIncreasing();
      sounds.backgroundMusicPlay();
    }
  }, [gameIsStarted, gameSettings]);

  useEffect(() => {
    if(livesCount <= 0) {
      sounds.backgroundMusicStop();
      sounds.loseSoundPlay();
      sessionStorage.setItem("score", score);
      navigate(GameResultScreenUrl);
    }
  }, [livesCount])

  useEffect(() => {
    if (isErrorOccurred) {
      throw new Error ('error has occurred in the GameScreen component');
    }
  }, [isErrorOccurred])

  // END Effects

  return (
    <div className={styles.wrapper}>
      <div className={styles.topElements}>
        <TopBar 
          livesCount={livesCount}
          score={score}
          livesCountDefault={livesCountDefault}
          isHidden={!gameIsStarted}
        />
        <div 
          className={cn(
            styles.timerWrapper,
            gameIsStarted ? styles.timerHidden : ""
          )}
        >
          <div className={styles.timer}>
            <p><strong>{timerPanelText}</strong></p>
          </div>
        </div>
      </div>

      <div 
        className={cn(
          styles.fallingObjectsWrapper,
          gameIsStarted ? styles.visible : "" 
        )}
      >
        <div className={styles.fallingObjectsInner} ref={fallingObjectsInnerRef}>
          <div className={styles.fallingObjects} ref={fallingObjectsRef}>
            {(
              fallingObjectsRows.length && 
              gameSettings && 
              gameSettings.columns
            ) && fallingObjectsRows.map(row => (
              <Row 
                row={row} 
                key={row.id}  
                ref={el => rowsRef.current[row.id] = el}
                fallingObjectRefs={fallingObjectRefs}
                columnsCount={gameSettings.columns}
              />
            ))}
          </div>
        </div>
      </div>
    
      <div className={styles.bottomElements}>
        <div className={styles.squirrelWrapper}>
          <Squirrel
            customRef={squirrelRef}
            leftPosition={squirrelPosition}
            customStyles={cn(
              styles.squirrel, 
              !(gameSettings && columnsCountRef.current) ? styles.invisible : "" ) }
            status={squirrelStatus}
            widthPercents={
              (gameSettings && columnsCountRef.current) && 
              calculateGridStepPercents(columnsCountRef.current) + 2
            }
            isBlinking={squirrelIsUntouchable}
          />
        </div>
        <div className={styles.arrows}>
          <img 
            src={ArrowLeft} 
            alt="left" 
            className={styles.arrowLeft}
            onClick={onArrowsClick}
            id="left"
          />
          <img 
            src={ArrowRight} 
            alt="right" 
            className={styles.arrowRight}
            onClick={onArrowsClick}
            id="right"
          />
        </div>
      </div> 
    </div> 
  );
}

export default GameScreen;