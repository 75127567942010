import React from "react";
import styles from './Cell.module.scss';
import { imageUrlBuilder } from '../../sanity';

import { rowsWidthToPercentage, getAlignItemsRandomly} from "./helpers";

const Cell = ({
  objectSize, 
  objectIcon, 
  objectId, 
  objectTitle, 
  columnsCount, 
  fallingObjectRefs
}) => {

  // if there is no size it is empty cell
  if (objectId && !objectSize) {
    return (
      <div 
        className={styles.foCell}
        style={{
          "width": `${rowsWidthToPercentage(1, columnsCount)}%`,
        }} 
      />)
  } else {

    return (
      <>
        {(objectId && objectIcon && objectTitle && objectSize) && (
          <div 
            className={styles.foCell}
            style={{
              "width": `${rowsWidthToPercentage(objectSize, columnsCount)}%`,
              "alignItems": `${getAlignItemsRandomly()}`
            }}
          >
            <img 
              src={imageUrlBuilder.image(objectIcon).url()}
              alt="falling object"
              id={objectId}
              title={objectTitle}
              ref={el => fallingObjectRefs.current[objectId] = el}
              style={{"transform": `${objectSize === 1 ? "translateX(50%)" : "translateX(25%)"}`}}
            />
          </div>
        )}
      </>
    )
  }
  
}

export default React.memo(Cell);