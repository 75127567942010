import 'modern-css-reset';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import App from './App';

import common_de from "./translations/de/common.json";
import common_fr from "./translations/fr/common.json";
import common_it from "./translations/it/common.json";

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
const vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

i18next
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false },  // React already does escaping
    fallbackLng: 'de',                        
    resources: {
      de: {
        common: common_de
      },
      fr: {
        common: common_fr               // 'common' is our custom namespace
      },
      it: {
        common: common_it
      }
    },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);