import SanityClient from '@sanity/client';
import ImageUrlBuilder from '@sanity/image-url';

export const sanity = SanityClient({
  projectId: 'ppjl034j',
  dataset: 'production',
  // this option enables faster responses
  // but can return stale data at times.
  // recommended for client-side queries
  useCdn: true,
  token: process.env.REACT_APP_TOKEN,
});

export const imageUrlBuilder = ImageUrlBuilder(sanity);
 