import nextId from "react-id-generator";
import { animationSpeedCoeff } from "./GameScreen";

export const calculateGridStepPercents = columnsCount => 100 / columnsCount;

export const animationTimeSecToIntervalTickSpeed = timeInSec => timeInSec * animationSpeedCoeff;

export const checkGameSettingsAreValid = settings => {
  const requiredParams = [
    "columns", 
    "acceleration", 
    "start_speed", 
    "interval"
  ];
  const settingsKeys = Object.keys(settings);

  return !requiredParams.find(requiredParam => {
    return !settingsKeys.includes(requiredParam);
  })
}

export const generateRandomIntegerInRange = (min, max) => 
  Math.floor(Math.random() * (max - min + 1)) + min;

export const arrayShuffle = arr => arr.sort(() => Math.random() - 0.5);

export const generateRow = (fallingObjects, columns) => {
  let maxObjectsCount;
  if (columns >= 7) {
    maxObjectsCount = 3;
  } else if (columns >= 5) {
    maxObjectsCount = 2
  } else {
    maxObjectsCount = 1
  }
  const countOfObjects = generateRandomIntegerInRange(0, maxObjectsCount);
  let row = { 
    id: nextId("row"), 
    objectsArr: [],
  };
  let rowsLength = 0;
  let fallingObjectsArr = [];
  let rowObjectsArr = [];
  for (let i = 0; i < countOfObjects; i++) {
    const indexOfObject = generateRandomIntegerInRange(0, fallingObjects.length - 1);
    rowsLength += fallingObjects[indexOfObject].size;
    fallingObjectsArr.push({ ...fallingObjects[indexOfObject], id: nextId("object") });
  }
  let emptyCellsArr = Array(columns - 1 - rowsLength ?? 0).fill(1);
  const emptyCellsWithObjectsArr = emptyCellsArr.map(() => ({id: nextId("object")}));
  rowObjectsArr = ([...arrayShuffle([
    ...fallingObjectsArr, 
    ...emptyCellsWithObjectsArr,
  ]), {id: nextId("object")}]);
  row.objectsArr = rowObjectsArr;
  return row;
}

export const generateRows = (rowsCount, fallingObjects, columnsCount) => {
  let rowsArr = [];
  for (let i = 0; i < rowsCount; i++) {
    rowsArr.push(generateRow(fallingObjects, columnsCount))
  }
  return rowsArr;
}

export const calculatePosition = (currentPosition, offsetDirection, gridStepPercents) => {
  let position = currentPosition;
  let direction = offsetDirection;
  if (offsetDirection === "left") {
    const newPosition = currentPosition - gridStepPercents;
    if (newPosition + 1 >= gridStepPercents) {
      position = newPosition;
    } 
    if (newPosition <= gridStepPercents) {
      direction = "straight";
    }
  } else if (offsetDirection === "right") {
    const newPosition = currentPosition + gridStepPercents;
    if (newPosition - 1 <= 100 - gridStepPercents) {
      position = newPosition;
    } 
    if (newPosition >= 100 - gridStepPercents) {
      direction = "straight";
    }
  }

  return { position, direction };
}

export const rowsWidthToPercentage = (objectRowsWidth, rowsCount) => {
  return (100 / rowsCount) * objectRowsWidth;
}

export const getOffset = el => {
  const rect = el.getBoundingClientRect();
  return {
    x: rect.left + window.scrollX,
    y: rect.top + window.scrollY
  };
}

export const isColliding = (a, b) => {
  const offsetA = getOffset(a);
  const offsetB = getOffset(b);
  return !(
    offsetB.x > offsetA.x + a.width || 
    offsetB.x + b.width < offsetA.x || 
    offsetB.y > offsetA.y + a.height || 
    offsetB.y + b.height < offsetA.y
  );
}

export const calculateAnimationIntervalTime = (
  prevIntervalTime, 
  speed, 
  acceleration, 
  startSpeed
) => {
  const accelerationTick = animationTimeSecToIntervalTickSpeed(acceleration);
  const speedIncreasingPercents = accelerationTick / startSpeed;
  return prevIntervalTime / (1 + (speed * (speedIncreasingPercents / 100)))
}

export const getTranslateYValue = string => 
  parseFloat(string.replace('translateY(', ''));

export const getAlignItemsRandomly = () => {
  const variants = ["flex-start", "flex-end", "center"];
  return variants[generateRandomIntegerInRange(0, variants.length - 1)];
}
 