import React from "react";
import styles from './Row.module.scss';
import Cell from "./Cell";


const Row = React.forwardRef(({row, fallingObjectRefs, columnsCount}, ref) => {

  return (
    <div 
      className={styles.foRow}
      ref={ref}
    >                     
      {row && row.objectsArr && row.objectsArr.length && row.objectsArr.map((object, index) => {
        if (object && object.id) {
          return (
            <Cell 
              objectId={object.id}
              objectSize={object.size}
              objectIcon={object.icon}
              objectTitle={object.title}
              fallingObjectRefs={fallingObjectRefs}
              columnsCount={columnsCount}
              key={object.id}
            />
          ) 
        }
      })}
    </div>  
  )
})

export default React.memo(Row);