import React from "react";
import cn from "classnames";
import styles from './Shields.module.scss';
import Shield from "./Shield";

function Shields({
  customWrapperStyles,
  leftShield,
  rightShield,
}) {
  return (
    <div className={cn(styles.wrapper, customWrapperStyles)}>
      {(leftShield && leftShield.text) && (
        <Shield 
          text={leftShield.text}
          onClick={leftShield.onClickHandler}
          customWrapperStyles={cn(
            styles.shield,
            styles.shieldLeft
          )} 
        />
      )}
      {(rightShield && rightShield.text) && (
        <Shield 
          type="right"
          onClick={rightShield.onClickHandler}
          text={rightShield.text} 
          customWrapperStyles={cn(
            styles.shield,
            styles.shieldRight
          )} 
        />
      )}
    </div>
  );
}

export default Shields;