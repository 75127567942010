import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./RankingListScreen.module.scss";
import Shields from "../components/Shields";
import InfoBlock from "../components/InfoBlock";
import { fetchLeadsBySite } from "../api";

const countOfLeadsWithCurrent = 5;

const Row = ({
  firstText = "", 
  secondText = "", 
  thirdText = "",
  wrapperCustomStyles = null
}) => (
  <li className={wrapperCustomStyles}>
    <p className={styles.first}>{firstText}</p>
    <p className={styles.second}>{secondText}</p>
    <p className={styles.third}>{thirdText}</p>
  </li>
)

// Helpers
const getCurrentPositionLeadsById = (id, allLeads) => {
  const plusMinusIndex = Math.floor((countOfLeadsWithCurrent - 1) / 2);
  const leadIndex = allLeads.findIndex(object => {
    return object._id === id;
  })
  let indexStart = leadIndex - plusMinusIndex;
  let indexEnd = leadIndex + plusMinusIndex + 1;
  let i = plusMinusIndex;
  while (i > 0) {
    if (!allLeads[leadIndex + i]) {
      indexStart -= 1;
      indexEnd -= 1;
    }
    i--;
  }
  const currentLeads = allLeads.slice(indexStart, indexEnd);
  let index = indexStart;
  const leadsWithIndexes = currentLeads.map(lead => {
    const leadWithIndex = {...lead, index: index};
    index++;
    return leadWithIndex;
  })
  return leadsWithIndexes;
}
// END Helpers

function RankingListScreen({ sounds }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation('common');
 
  const [firstPositionsLeads, setFirstPositionsLeads] = useState([]); 
  const [currentPositionLeads, setCurrentPositionLeads] = useState([]); 
  const [isErrorOccurred, setIsErrorOccurred] = useState(false);

  const leadId = location.state.leadId;

  const navigateToFirstScreen = useCallback(() => {
    sounds.changeScreenSoundPlay();
    navigate('/');
  }, [navigate, sounds]);

  const onResponseError = () => {
    setIsErrorOccurred(true);
  }

  const onKeyDown = useCallback(e => {
    if (e.keyCode == "39") {
      navigateToFirstScreen();
    }
  }, [navigate]);

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener("keydown", onKeyDown);
    }, 1000);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, []);

  useEffect(() => {
    // If there is no leadId logic will be broken, 
    // so redirect to first screen
    if (!leadId) {
      navigateToFirstScreen();
    }
    const fetchAndSetLeads = async () => {
      const siteLeads = await fetchLeadsBySite(7, onResponseError);
      if (siteLeads && siteLeads.leads && siteLeads.leads.length) {
        const leads = siteLeads.leads;
        if (!leads.find(lead => lead._id === leadId)) {
          setFirstPositionsLeads(leads.slice(0, 3));
          const allSiteLeads = await fetchLeadsBySite(null, onResponseError);
          if (allSiteLeads && allSiteLeads.leads && allSiteLeads.leads.length) {
            const currentLeads = getCurrentPositionLeadsById(leadId, allSiteLeads.leads);
            setCurrentPositionLeads(currentLeads);
          }
        } else {
          setFirstPositionsLeads(leads);
        }
      }
    }
    fetchAndSetLeads();
  }, [])

  useEffect(() => {
    if (isErrorOccurred) {
      throw new Error ('error has occurred in the RankingList component');
    }
  }, [isErrorOccurred])

  return (
    <div className={styles.wrapper}>
      <InfoBlock customStyles={styles.infoBlock}>
        <h2>{t("Rangliste")}</h2>
        <ul className={styles.rankingList}>
          <Row 
            firstText={t("Rang")}
            secondText={t("Vorname")}
            thirdText={t("Punkte")}
            wrapperCustomStyles={styles.firstRow}
          />
          { firstPositionsLeads && firstPositionsLeads.map((lead, index) => (
            <Row 
              key={lead._id}
              firstText={index + 1}
              secondText={lead.first_name}
              thirdText={lead.score}
              wrapperCustomStyles={leadId === lead._id ? styles.currentLeadRow : ""}
            />
          ))}
          <Row 
            firstText={". . ."}
            secondText={". . ."}
            thirdText={". . ."}
          />
          { currentPositionLeads && currentPositionLeads.map(lead => (
            <Row 
              key={lead._id}
              firstText={lead.index + 1}
              secondText={lead.first_name}
              thirdText={lead.score}
              wrapperCustomStyles={leadId === lead._id ? styles.currentLeadRow : ""}
            />
          ))}
        </ul>
      </InfoBlock>
      <Shields 
        rightShield={{
          text: t("Erneut spielen"),
          onClickHandler: navigateToFirstScreen,
        }}
      />
    </div>
    
  );
}

export default RankingListScreen;