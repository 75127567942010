import React, { useCallback, useEffect } from "react"
import styles from './FirstScreen.module.scss';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import Squirrel from "../../components/Squirrel";
import LanguageSwitcher from "./LanguageSwitcher";
import Shield from "../../components/Shield"
import Logo from "../../components/Logo";
import Wandergame from "../../components/Wandergame";

import { GameDescriptionScreenUrl, languages } from '../../App';

function FirstScreen({ sounds }) {
  
  const navigate = useNavigate();
  const [t, i18n] = useTranslation('common');

  const currentLang = i18n.language;
  let currentLanguage;
  if (currentLang) {
    if (languages.includes(currentLang)) {
      currentLanguage = currentLang;
    } else {
      const currentLangArr = currentLang.split("-");
      const browserLang = currentLangArr[0];
      if (browserLang && languages.includes(browserLang)) {
        currentLanguage = browserLang;
      } 
    }
  }

  const navigateToNextScreen = useCallback(() => {
    sounds.changeScreenSoundPlay();
    navigate(GameDescriptionScreenUrl);
  }, [navigate, sounds])

  const onKeyDown = useCallback(e => {
    if (e.keyCode == "39") {
      navigateToNextScreen();
    }
  }, [navigate]);

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.languageSwitcherWrapper}>
        <LanguageSwitcher currentLang={currentLanguage} />
      </div>
      <div className={styles.logosWrapper}>
        <Logo />
        <div className={styles.wandergameWrapper}> 
          <Wandergame lang={currentLanguage} />
        </div>
      </div>
      <div className={styles.bottomElements}>
        <div className={styles.squirrelWrapper}>
          <Squirrel customStyles={styles.squirrel}/>
        </div>
        <Shield 
          type="right"
          onClick={navigateToNextScreen}
          text={t("Spiel starten!")}
          customWrapperStyles={styles.shieldWrapper}
        />
      </div>
    </div> 
  );
}

export default FirstScreen;
