import { sanity } from './sanity';

// TODO: get this param from outside
const defaultWebsiteId = "87777b73-544d-4931-905a-bbf708acc0e5";

function getWebsiteId() {
  return sessionStorage.getItem("websiteId") || defaultWebsiteId;
}

export async function fetchFallingObjects(onError) {
  return await sanity
    .fetch(`*[_type == "falling_object"] {
      title,
      icon,
      points,
      size,
      danger_object,
    }`)
    .catch((e) => { 
      console.error(e);
      if (onError && typeof onError === 'function') {
        onError();
      }
    });
}

export async function fetchTranslations() {
  return await sanity
    .fetch(`
    *[_type == "site" && _id == "${ getWebsiteId() }"][0] {
      "translations": *[_type == "translation" && _id == ^.translations._ref]
    }`)
    .catch(err => console.error(err));
}

export async function fetchGameSettings(onError) {
  return await sanity
    .fetch(`
    *[_type == "site" && _id == "${ getWebsiteId() }"][0] {
      "engine": *[_type == "engine" && _id == ^.engine._ref]
    }`)
    .catch((e) => {
      console.error(e);
      if (onError && typeof onError === 'function') {
        onError();
      }
    });
}


export async function fetchLeadsBySite(count, onError) {
  const countQueryPart = count ? ` [0..${count}]` : "";
  try {
    // Use client to create a new document in Sanity with an object
    return await sanity
      .fetch(`*[_id == "${ getWebsiteId() }"][0] {
        _id,
        site,
        "leads": *[_type=='lead' && references(^._id)] | order(_createdAt desc) | order(score desc) ${countQueryPart} {
          _id,
          _createdAt, 
          first_name, 
          score,
          email
        } 
      }`)
  } catch (err) {
    console.error(err)
    if (onError && typeof onError === 'function') {
      onError();
    }
  }
}

export async function createLead(formData, onError) {
  const leadData = {
    _type: 'lead',
    site: {
      _type: 'reference',
      _ref: getWebsiteId(),
    },
    ...formData,
  }
  try {
    // Use our Client to create a new document in Sanity with an object
    return await sanity.create(leadData);
  } catch (err) {
    console.error(err);
    if (onError && typeof onError === 'function') {
      onError();
    }
  }
}