import React from "react";
import cn from "classnames";
import styles from './InfoBlock.module.scss';

function InfoBlock({ children, customStyles }) {
  return (
    <div className={cn(styles.wrapper, customStyles)}>
      {children}
    </div> 
  );
}

export default InfoBlock;