import React from "react"
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import useSound from 'use-sound';
import { ErrorBoundary } from 'react-error-boundary';
import FirstScreen from './screens/FirstScreen/FirstScreen';
import NotFound from './screens/NotFound';
import styles from './App.module.css';
import GameDescriptionScreen from './screens/GameDescriptionScreen';
import GameScreen from './screens/GameScreen/GameScreen';
import GameResultScreen from './screens/GameResultScreen';
import EnterYourDataScreen from './screens/EnterYourDataScreen/EnterYourDataScreen';
import RankingListScreen from './screens/RankingListScreen';
import ErrorFallback from "./ErrorFallback";
import ImagePreloading from "./ImagePreloading";

import timerTickSoundFile from './sounds/timer-tick.mp3';
import backgroundMusicFile from './sounds/background-music.mp3';
import hitSoundFile from './sounds/fart.mp3';
import catchSoundFile from './sounds/slide.mp3';
import loseSoundFile from './sounds/lose.mp3';
import changeScreenSoundFile from './sounds/change-screen.mp3';

export const GameDescriptionScreenUrl = "/game-description";
export const GameScreenUrl = "/game";
export const GameResultScreenUrl = "/game-result";  
export const EnterYourDataScreenUrl = "/enter-your-data"; 
export const RankingListScreenUrl = "/ranking-list"; 

export const languages = ["de", "fr", "it"];


function App() {
  
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const websiteId = searchParams.get("website_id");
  if (websiteId) {
    sessionStorage.setItem("websiteId", websiteId);
  }

  const [timerTickSoundPlay] = useSound(timerTickSoundFile);
  const [
    backgroundMusicPlay,
    { stop: backgroundMusicStop }] = 
    useSound(backgroundMusicFile, { volume: 0.8, loop: true, });
  const [hitSoundPlay] = useSound(hitSoundFile);
  const [catchSoundPlay] = useSound(catchSoundFile);
  const [loseSoundPlay] = useSound(loseSoundFile, { volume: 0.7 });
  const [changeScreenSoundPlay] = 
    useSound(changeScreenSoundFile, { volume: 0.5 });

  const sounds = {
    timerTickSoundPlay,
    backgroundMusicPlay,
    backgroundMusicStop,
    hitSoundPlay,
    catchSoundPlay,
    loseSoundPlay,
    changeScreenSoundPlay
  }

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <ImagePreloading />
        <ErrorBoundary 
          FallbackComponent={ErrorFallback}
          onReset={() => navigate("/")}
        >
          <Routes>
            <Route 
              path="/" 
              element={<FirstScreen sounds={sounds} />} 
            />
            <Route 
              path={GameDescriptionScreenUrl} 
              element={<GameDescriptionScreen sounds={sounds} />} 
            />
            <Route 
              path={GameScreenUrl} 
              element={<GameScreen sounds={sounds} />} 
            />
            <Route 
              path={GameResultScreenUrl} 
              element={<GameResultScreen sounds={sounds} />} 
            />
            <Route 
              path={EnterYourDataScreenUrl} 
              element={<EnterYourDataScreen sounds={sounds} />} 
            />
            <Route 
              path={RankingListScreenUrl} 
              element={<RankingListScreen sounds={sounds} />} 
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default App;
