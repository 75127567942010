import React from "react"

import WandergameDE from '../images/wandergame.svg';
import WandergameIT from '../images/wandergame_it.svg';
import WandergameFR from '../images/wandergame_fr.svg';

function Wandergame({ lang = "de" }) {
  let Wandergame;
  switch(lang) {
    case "it": Wandergame = WandergameIT
    break
    case "fr": Wandergame = WandergameFR
    break
    default: Wandergame = WandergameDE
  }
  return (
    <img src={Wandergame} alt="wandergame" />
  );
}

export default Wandergame;