import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import styles from './Webform.module.scss';

function Webform({ 
  handleSubmit, 
  formData, 
  handleChange,
  onTermsButtonClick,
  hasSubmitBtnClicked,
  isSubmitting,
  hasSubmitted,
  setFocus,
  formDataErrors
}) {

  const { t, i18n } = useTranslation('common');
  
  if (isSubmitting) {
    // Returns a "Sending you data" state if being processed
    return <p>{t("sending-your-data")}</p>
  }
  if (hasSubmitted) {
    // Returns the message that a user has successfully submitted the form
    return (
      <h3>{t("your-data-has-been-sent")}</h3>
    )
  }


  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <div className={cn(
            styles.genderWrapper,
            (hasSubmitBtnClicked && !formData.gender) ? styles.error : ""
          )}>
            <div className={cn(styles.radio, "radio")}>
              <label>
                <div className={styles.radioInputWrapper}>
                  <input
                    type="radio"
                    name="gender"
                    value="boy"
                    checked={formData.gender === "boy"}
                    onChange={handleChange}
                    className={(hasSubmitBtnClicked && formDataErrors.gender) ? styles.required : ""}
                  />
                </div>
                <div className={styles.radioTextWrapper}>
                  <p>{t("Mann")}</p>
                </div>
              </label>
            </div>
            <div className={cn(styles.radio, "radio")}>
              <label>
                <div className={styles.radioInputWrapper}>
                  <input
                    type="radio"
                    name="gender"
                    value="girl"
                    checked={formData.gender === "girl"}
                    onChange={handleChange}
                    className={(hasSubmitBtnClicked && formDataErrors.gender) ? styles.required : ""}
                  />
                </div>
                <div className={styles.radioTextWrapper}>
                  <p>{t("Frau")}</p>
                </div>
              </label>
            </div>
            <div className={cn(styles.radio, "radio")}>
              <label>
                <div className={styles.radioInputWrapper}>
                  <input
                    type="radio"
                    name="gender"
                    value="other"
                    checked={formData.gender === "other"}
                    onChange={handleChange}
                    className={(hasSubmitBtnClicked && formDataErrors.gender) ? styles.required : ""}
                  />
                </div>
                <div className={styles.radioTextWrapper}>
                  <p>{t("Divers")}</p>
                </div>
              </label>
            </div>
          </div>
          {hasSubmitBtnClicked && formDataErrors.gender && (
            <div className={styles.errorMessage}>
              <p>{formDataErrors.gender}</p>
            </div>
          )}
        </fieldset>
        <fieldset className={(hasSubmitBtnClicked && formDataErrors.first_name) ? styles.error : ""}>
          <label>
            <input 
              name="first_name" 
              type="text"
              placeholder={t("Vorname")}
              onChange={handleChange} 
              value={formData.first_name}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            />
          </label>
          {hasSubmitBtnClicked && formDataErrors.first_name && (
            <div className={styles.errorMessage}>
              <p>{formDataErrors.first_name}</p>
            </div>
          )}
        </fieldset>
        <fieldset className={(hasSubmitBtnClicked && formDataErrors.last_name) ? styles.error : ""}>
          <label>
            <input 
              name="last_name" 
              type="text"
              placeholder={t("Nachname")}
              onChange={handleChange} 
              value={formData.last_name}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            />
          </label>
          {hasSubmitBtnClicked && formDataErrors.last_name && (
            <div className={styles.errorMessage}>
              <p>{formDataErrors.last_name}</p>
            </div>
          )}
        </fieldset>
        <fieldset className={(hasSubmitBtnClicked && formDataErrors.email) ? styles.error : ""}>
          <label>
            <input 
              type="email"
              name="email" 
              placeholder={t("E-Mail")}
              onChange={handleChange} 
              value={formData.email}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            />
          </label>
          {hasSubmitBtnClicked && formDataErrors.email && (
            <div className={styles.errorMessage}>
              <p>{formDataErrors.email}</p>
            </div>
          )}
        </fieldset>
        <fieldset>
          <div className={styles.termsLabel}>
            <input 
              type="checkbox" 
              name="terms_checkbox" 
              onChange={handleChange} 
              checked={formData.terms_checkbox}
              className={(hasSubmitBtnClicked && formDataErrors.terms_checkbox) ? styles.required : ""}
            />
            <p onClick={onTermsButtonClick}>
              {t("Teilnahmebedingungen")}
            </p>
          </div>
          {hasSubmitBtnClicked && formDataErrors.terms_checkbox && (
            <div className={styles.errorMessage}>
              <p>{formDataErrors.terms_checkbox}</p>
            </div>
          )}
        </fieldset>
        <div className={styles.submitButtonWrapper}>
          <button type="submit">{t("senden")}</button>
        </div>
      </form>
    </div>
  );
}

export default Webform;