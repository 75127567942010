import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useCustomT } from "../../hooks/useCustomT";
import styles from './EnterYourDataScreen.module.scss';
import Shields from "../../components/Shields";
import InfoBlock from "../../components/InfoBlock";
import Webform from "./Webform";
import { createLead, fetchTranslations } from "../../api";
import { BasicRichText } from "../../components/BasicRichText";

import { RankingListScreenUrl } from '../../App';

const defaultFormErrorsObj = {
  gender: "",
  first_name: "",
  last_name: "",
  email: "",
  terms_checkbox: ""
};

function EnterYourDataScreen({ sounds }) {
  const { t, i18n } = useTranslation('common');
  const navigate = useNavigate();
  const customT = useCustomT();

  const [formData, setFormData] = useState({
    gender: null,
    first_name: "",
    last_name: "",
    email: "",
    terms_checkbox: false,
  });
  const [formDataErrors, setFormDataErrors] = useState(defaultFormErrorsObj);
  const [hasSubmitBtnClicked, setHasSubmitBtnClicked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false); 
  const [timeoutGoToNextScreenId, setTimeoutGoToNextScreenId] = useState(null);
  const [createdLeadId, setCreatedLeadId] = useState(null);
  const [termsText, setTermsText] = useState(null);

  const [isTermsShown, setIsTermsShown] = useState(false);
  const [focus, setFocus] = useState(false);
  const [isErrorOccurred, setIsErrorOccurred] = useState(false);


  const navigateToFirstScreen = useCallback(() => {
    sounds.changeScreenSoundPlay();
    navigate('/');
  }, [sounds]);

  const navigateToNextScreen = useCallback(() => {
    if (createdLeadId) {
      sounds.changeScreenSoundPlay();
      navigate(RankingListScreenUrl, { state: { leadId: createdLeadId } });
    } else {
      console.warn("There is no leadId to go to the Ranking List");
    }
  }, [createdLeadId, sounds]);

  const setTimerGoToNextScreen = () => {
    const timeoutId = setTimeout(navigateToNextScreen, 10000);
    setTimeoutGoToNextScreenId(timeoutId);
  }

  const handleSubmit = useCallback(async event => {
    event.preventDefault();
    if (!hasSubmitBtnClicked) {
      setHasSubmitBtnClicked(true);
    }
    const score = sessionStorage.getItem("score");
    if (isFormValid && score) {
      const leadData = {
        salutation: formData.gender,
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        language: i18n.language,
        score: +score,
      }
      try {
        setIsSubmitting(true);
        const leadResponse = await createLead(leadData, onResponseError);
        setCreatedLeadId(leadResponse._id);
        setIsSubmitting(false);
        setHasSubmitted(true);
        sessionStorage.removeItem("score");
        setTimerGoToNextScreen();
      } catch (err) {
        console.warn(err)  
      }
    }
  }, [hasSubmitBtnClicked, setHasSubmitBtnClicked, isFormValid])

  const handleFormChange = event => {
    const isCheckbox = event.target.type === 'checkbox';
    setFormData(oldFormData => {
      return {
        ...oldFormData,
        [event.target.name]: isCheckbox ? event.target.checked : event.target.value,
      }
    });
  }

  const checkEmailIsValid = email => {
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return email.match(mailFormat);
  }

  const formValidation = formData => {
    const errorsObj = {...defaultFormErrorsObj};
    if (!formData.gender) {
      errorsObj["gender"] = t("field-is-required");
    }
    if (formData.first_name) {
      if (formData.first_name.length < 2) {
        errorsObj["first_name"] = t("field-length-2-symbols");
      }
    } else {
      errorsObj["first_name"] = t("field-is-required");
    }
    if (formData.last_name) {
      if (formData.last_name.length < 2) {
        errorsObj["last_name"] = t("field-length-2-symbols");
      }
    } else {
      errorsObj["last_name"] = t("field-is-required");
    }
    if (formData.email) {
      if (!checkEmailIsValid(formData.email)) {
        errorsObj["email"] = t("use-correct-email-format");
      }
    } else {
      errorsObj["email"] = t("field-is-required");
    }
    if (!formData.terms_checkbox) {
      errorsObj["terms_checkbox"] = t("field-is-required");
    }

    setFormDataErrors(errorsObj);

    const keys = Object.keys(errorsObj);
    return !keys.find(key => errorsObj[key]);
  }

  const onTermsButtonClick = useCallback(() => {
    setIsTermsShown(true);
  }, [setIsTermsShown]);

  const closeTerms = useCallback(() => {
    setIsTermsShown(false);
  }, [setIsTermsShown])

  const onLeftArrowKeyDown = useCallback(e => {
    if (e.keyCode == "37") {
      navigateToFirstScreen();
    }
  }, [navigateToFirstScreen]);

  const onRightArrowKeyDown = useCallback(e => {
    if (e.keyCode == "39") {
      navigateToNextScreen();
    }
  }, [navigateToNextScreen, createdLeadId]);

  // use such approach because Error Boundry can't
  // catch error from asynchronous code  
  const onResponseError = () => {
    setIsErrorOccurred(true);
  }

  // Effects
  useEffect(() => {
    if (focus || isTermsShown) {
      window.removeEventListener("keydown", onLeftArrowKeyDown)
    } else {
      window.addEventListener("keydown", onLeftArrowKeyDown);
    }
    return () => window.removeEventListener("keydown", onLeftArrowKeyDown);
  }, [focus, isTermsShown]);

  useEffect(() => {
    if (hasSubmitted) {
      window.removeEventListener("keydown", onLeftArrowKeyDown);
      window.addEventListener("keydown", onRightArrowKeyDown);
    }
    return () => window.removeEventListener("keydown", onRightArrowKeyDown);
  }, [hasSubmitted]);

  useEffect(() => {
    const isValid = formValidation(formData);
    if (isValid) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formData]);

  useEffect(() => {
    const fetchAndSetTermsText = async () => {
      const resp = await fetchTranslations();
      if (resp.translations && resp.translations.length) {
        const terms = resp.translations[0].terms;
        if (terms) {
          setTermsText(terms);
        }
      }
    }
    fetchAndSetTermsText();
  }, [])

  useEffect(() => {
    return () => clearTimeout(timeoutGoToNextScreenId);
  }, [timeoutGoToNextScreenId])

  useEffect(() => {
    if (isErrorOccurred) {
      throw new Error ('error has occurred in the EnterYourDataScreen component');
    }
  }, [isErrorOccurred])

  // END Effects

  return (
    <div className={styles.wrapper}>
      <InfoBlock customStyles={styles.infoBlock}>
        { !isTermsShown ? (
          <Webform 
            handleSubmit={handleSubmit}
            handleChange={handleFormChange}
            formData={formData}
            onTermsButtonClick={onTermsButtonClick}
            hasSubmitBtnClicked={hasSubmitBtnClicked}
            isFormValid={isFormValid}
            hasSubmitted={hasSubmitted}
            isSubmitting={isSubmitting}
            setFocus={setFocus}
            formDataErrors={formDataErrors}
          />
        ) : (
          <div>
            { termsText && (
              <BasicRichText blocks={customT(termsText)} />
            )}
          </div>
        ) }
      </InfoBlock>
      { !isTermsShown ? (
        <Shields 
          leftShield={!hasSubmitted ? {
            text: t("Zurück"),
            onClickHandler: navigateToFirstScreen,
          } : null}
          rightShield={hasSubmitted ? {
            text: t("Rangliste"),
            onClickHandler: navigateToNextScreen,
          } : null}
        />
      ) : (
        <Shields 
          rightShield={{
            text: t("Weiter"),
            onClickHandler: closeTerms,
          }}
        />
      ) }
    </div>
    
  );
}

export default EnterYourDataScreen;