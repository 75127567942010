import React from "react";
import { useTranslation } from "react-i18next";

export const useCustomT = () => {

  const [t, i18n] = useTranslation('common');

  const lang = i18n.language;
  const fallback = i18n.options.fallbackLng[0];

  return obj => {
    let translation = "";
    if (obj) {
      if (lang && obj[lang]) {
        translation = obj[lang];
      } else if (obj[fallback]) {
        translation = obj[fallback];
      }
    }
    return translation;
  }
}