import React from "react";
import styles from './ImagePreloading.module.css';

import SquirrelStraight from './images/squirrel.svg';
import SquirrelLeft from './images/squirrel-left.svg';
import SquirrelRight from './images/squirrel-right.svg';
import SquirrelHit from './images/squirrel-hit.svg';
import SquirrelCatch from './images/squirrel-catch.svg';
import Description from "./images/explanation.svg";
import ArrowLeft from "./images/arrow-left.svg";
import ArrowRight from "./images/arrow-right.svg";
import HeartFull  from "./images/heart-full.svg";
import HeartEmpty from "./images/heart-empty.svg";
import ShieldLeft from "./images/shield-left.svg";
import ShieldRight from "./images/shield-right.svg";

const imagesArr = [
  SquirrelStraight,
  SquirrelLeft,
  SquirrelRight,
  SquirrelHit,
  SquirrelCatch,
  Description,
  ArrowLeft,
  ArrowRight,
  HeartFull,
  HeartEmpty,
  ShieldLeft,
  ShieldRight
]

// This component is not necessary. It is a way to preload images 
// that is better to be preloaded
const ImagePreloading = () => (
  <div className={styles.wrapper}>
    {(imagesArr && imagesArr.length) && imagesArr.map((image, index) => (
      <img src={image} alt="image" key={index} />
    ))}
  </div>
)

export default ImagePreloading;