import React from "react";
import { useTranslation } from "react-i18next";

import InfoBlock from "./components/InfoBlock";
import Shields from "./components/Shields";

const ErrorFallback = ({error, componentStack, resetErrorBoundary}) => {
  
  const { t, i18n } = useTranslation('common');

  return (
    <div role="alert">
      <InfoBlock>
        <h2>{t("something-went-wrong-title")}</h2>
        <p>{t("something-went-wrong-text")}</p>
      </InfoBlock>
      <Shields 
        rightShield={{
          text: t("Zum Start"),
          onClickHandler: resetErrorBoundary,
        }}
      />
    </div>
  );
};

export default ErrorFallback;
