import React, { useCallback, useEffect } from "react"
import cn from "classnames";
import styles from './GameDescriptionScreen.module.scss';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import Shields from "../components/Shields";
import InfoBlock from "../components/InfoBlock";

import { GameScreenUrl } from '../App';

import DescriptionImage from "../images/explanation.svg";

function GameDescriptionScreen({ sounds }) {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation('common');

  const navigateToNextScreen = useCallback(() => {
    sounds.changeScreenSoundPlay();
    navigate(GameScreenUrl);
  }, [navigate, sounds])

  const onKeyDown = useCallback(e => {
    if (e.keyCode == "39") {
      navigateToNextScreen();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, []);

  return (
    <div className={styles.wrapper}>
      <InfoBlock>
        <div className={styles.descriptionGame}>
          <div className={styles.topTextBlock}>
            <h2 className={cn(
              styles.descriptionGameTitle, 
              styles.marginBottom
            )}>
              {t("Anleitung")}
            </h2>
            <p className={styles.marginBottom}>
              {t("game-description-part-1")}
            </p>
            <p className={styles.marginBottom}>
              {t("game-description-part-2")}
            </p>
          </div> 
          <img 
            src={DescriptionImage} 
            className={cn(
              styles.gameDescriptionImage, 
              styles.marginBottom
            )}
            alt="description"
          />
          <div className={styles.bottomTextBlock}>
            <p>
              <strong>{t("Viel Glück!")}</strong>
            </p>
          </div>
        </div>
      </InfoBlock>
      <Shields 
        customWrapperStyles={styles.shieldsWrapper}
        rightShield={{
          text: t("Spiel starten!"),
          onClickHandler: navigateToNextScreen,
        }}
      />
    </div> 
  )
}

export default GameDescriptionScreen;