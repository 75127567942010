import React from "react"
import PostLogo from '../images/logo-post.svg';

function Logo() {
  return (
      <img src={PostLogo} alt="logo" height="110px"/>
  )
}

export default Logo;
